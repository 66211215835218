import React from 'react';

import { Box, Grid } from '@core';
import { ImageCore, Text } from '@components';

const InfluencerCard = ({ image, author, description, comment }) => (
  <Grid.Container py={32} px={24}>
    <Grid.Row mb={24} alignItems="center" flexWrap="nowrap">
      <Box maxWidth={90} display="flex" flexDirection="column">
        <ImageCore src={image.src} alt={image.alt} />
      </Box>
      <Box ml={24} flexShrink={0}>
        <Text.Body1Strong>{author}</Text.Body1Strong>
        <Text.Body2>{description}</Text.Body2>
      </Box>
    </Grid.Row>
    <Grid.Row>
      <Text.Body2 textAlign="left">{comment}</Text.Body2>
    </Grid.Row>
  </Grid.Container>
);

export default InfluencerCard;
